import { IUser, IUserLogin, IUserRegister } from "../interfaces/IUser";
import Helper from "./helpers/helper";

const authApi = Helper.apiUrl + "authenticate";
class AuthApi {
  static login(user: IUserLogin) {
    return new Promise<IUser>((resolve, reject) => {
      if (user.username.length < 3 || user.password.length < 3) {
        reject("Min length is 3 chars!");
      }
      fetch(authApi + "/login", {
        method: "POST",
        body: JSON.stringify({
          username: user.username,
          password: user.password,
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          (result: any) => {
            if (!result.error) {
              resolve(result);
            } else {
              reject(result.error);
            }
          },
          (error) => {
            reject(error.message);
          }
        );
    });
  }

  static register(user: IUserRegister) {
    return new Promise<boolean>((resolve, reject) => {
      if (user.username.length < 3 || user.password.length < 3) {
        reject("Min length is 3 chars!");
      }
      fetch(authApi + "/register", {
        method: "POST",
        body: JSON.stringify(user),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          (result: any) => {
            if (!result.error) {
              resolve(true);
            } else {
              reject(result.error);
            }
          },
          (error) => {
            reject(error.message);
          }
        );
    });
  }

  static logout() {
    return new Promise((resolve, reject) => {
      fetch(authApi + "/logout", {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          (result: any) => {
            if (!result.error) {
              resolve(true);
            } else {
              reject(result.error);
            }
          },
          (error) => {
            reject(error.message);
          }
        );
    });
  }

  static getUserInfo() {
    return new Promise((resolve, reject) => {
      fetch(authApi + "/userInfo")
        .then((res) => res.json())
        .then(
          (result: any) => {
            if (!result.error) {
              resolve(result);
            } else {
              reject(result.error);
            }
          },
          (error) => {
            reject(error.message);
          }
        );
    });
  }
  static forgottenPassword(email: string) {
    return new Promise<boolean>((resolve, reject) => {
      fetch(authApi + "/forgottenPassword", {
        method: "POST",
        body: JSON.stringify({
          email
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          (result: any) => {
            if (!result.error) {
              resolve(true);
            } else {
              reject(result.error);
            }
          },
          (error) => {
            reject(error.message);
          }
        );
    });
  }
  static resetPassword(token: string, password: string) {
    return new Promise<boolean>((resolve, reject) => {
      fetch(authApi + "/passwordReset", {
        method: "POST",
        body: JSON.stringify({
          token,
          password
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          (result: any) => {
            if (!result.error) {
              resolve(true);
            } else {
              reject(result.error);
            }
          },
          (error) => {
            reject(error.message);
          }
        );
    });
  }
}
export default AuthApi;
