import { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { IUser } from "../../interfaces/IUser";
import { bindActionCreators } from "redux";
import * as authAction from "../../actions/authAction";
import userApi from "../../api/authApi";
export interface IHeaderProps {
  userData: IUser;
  authAction: any;
  history: any;
}
export interface IHeaderState {
  loading: boolean;
}
export class Header extends Component<IHeaderProps, IHeaderState> {
  constructor(props: IHeaderProps) {
    super(props);
    this.state = {
      loading: false,
    };
    this.logout = this.logout.bind(this);
  }

  async logout() {
    if (this.state.loading) {
      return;
    }
    try {
      this.setState({
        loading: true,
      });
      await userApi.logout();
      await this.props.authAction.logout();
      this.setState({
        loading: false,
      });
      this.props.history.push("/login");
    } catch (err) {
      this.setState({
        loading: false,
      });
      console.log(err);
    }
  }

  render() {
    return (
      <div>
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <NavLink className="navbar-brand" to={`/`}>
            Food Delivery
          </NavLink>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarNavAltMarkup"
            aria-controls="navbarNavAltMarkup"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
            <div className="navbar-nav">
              <NavLink
                className={
                  this.props.userData.username ? "nav-item nav-link" : "d-none"
                }
                id="header-menu-restaurants"
                to={`/`}
                activeClassName="active"
                exact
              >
                Restaurants
              </NavLink>
              <NavLink
                className={
                  this.props.userData.username ? "nav-item nav-link" : "d-none"
                }
                id="header-menu-orders"
                to={`/orders`}
                activeClassName="active"
                exact
              >
                Orders
              </NavLink>
              <NavLink
                className={
                  this.props.userData.username ? "d-none" : "nav-item nav-link"
                }
                id="header-menu-login"
                to={`/login`}
                activeClassName="active"
              >
                Login
              </NavLink>
              <NavLink
                className={
                  this.props.userData.username ? "d-none" : "nav-item nav-link"
                }
                id="header-menu-register"
                to={`/register`}
                activeClassName="active"
              >
                Register
              </NavLink>
              <span
                role="button"
                onClick={this.logout}
                className={
                  !this.props.userData.username ? "d-none" : "nav-item nav-link"
                }
                id="header-menu-logout"
              >
                {this.state.loading ? "Logout..." : "Logout"}
              </span>
            </div>
            <div className="navbar-nav ml-auto">
              <span className="nav-item nav-link navbar-right">
                {this.props.userData.fullname
                  ? this.props.userData.fullname
                  : ""}
              </span>
            </div>
          </div>
        </nav>
      </div>
    );
  }
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    userData: state.user,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    authAction: bindActionCreators(authAction, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
