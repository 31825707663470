import { createStore, applyMiddleware } from "redux";
import rootReducer from "../reducers";

import {
  createStateSyncMiddleware,
  initStateWithPrevTab,
} from "redux-state-sync";

const config = {};
const middlewares = [createStateSyncMiddleware(config)];

const store = createStore(rootReducer, {}, applyMiddleware(...middlewares));
initStateWithPrevTab(store);

export default store;
