import { IOrderDetails } from "../interfaces/IOrder";
const initialStateOrderDetails: IOrderDetails = {
    id: 0,
    datetime: "",
    totalsum: 0,
    restaurant_id: 0,
    restaurant_name: "",
    user_id: 0,
    user_username: "",
    user_fullname: "",
    status_name: "",
    status_title: "",
    status_last_element: -1,
    orderHistory: [],
    orderedMeals: [],
    userBlocked: 0,
};
export default initialStateOrderDetails;
