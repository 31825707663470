import { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import authApi from "../../api/authApi";
import Helper from "../../api/helpers/helper";
import { IUser, IUserRegister } from "../../interfaces/IUser";

export interface IRegisterProps {
  userData: IUser;
  history: any;
}
export interface IRegisterState {
  username: string;
  password: string;
  confirmPassword: string;
  email: string;
  fullname: string;
  role: string;
  error: string;
  loading: boolean;
}
export class Register extends Component<IRegisterProps, IRegisterState> {
  constructor(props: any) {
    super(props);

    this.state = {
      username: "",
      password: "",
      confirmPassword: "",
      email: "",
      fullname: "",
      error: "",
      role: "",
      loading: false,
    };

    this.register = this.register.bind(this);
  }

  componentDidMount() {
    if (this.props.userData.username) {
      this.redirect("/");
    }
  }
  redirect = (path: string) => {
    this.props.history.push(path);
  };
  onChangeHandler = (event: any) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };
  onKeyDown = (event: any) => {
    if (event.key === "Enter" && !this.state.loading) {
      this.register();
    }
  };

  async register() {
    try {
      if (
        !this.state.username ||
        !this.state.password ||
        !this.state.fullname ||
        !this.state.email ||
        !this.state.role
      ) {
        this.setState({
          error: "All fields are required!",
        });
        return;
      } else if (this.state.password != this.state.confirmPassword) {
        this.setState({
          error: "Confirm password field must match password field!",
        });
        return;
      } else if (!Helper.isValidEmail(this.state.email)) {
        this.setState({
          error: "Please provide valid email address!",
        });
        return;
      }
      this.setState({
        loading: true,
      });
      const userRegister: IUserRegister = {
        username: this.state.username,
        password: this.state.password,
        email: this.state.email,
        fullname: this.state.fullname,
        role: this.state.role,
      };
      await authApi.register(userRegister);
      this.setState({
        error: "",
        loading: false,
      });
      this.redirect("/login");
    } catch (err) {
      this.setState({
        error: err,
        loading: false,
      });
    }
  }

  render() {
    return (
      <div className="register-login">
        <div className="col-sm-12">
          <h2>Sign Up</h2>
          {this.state.error && (
            <div id="error" className="text-danger">
              {this.state.error}
            </div>
          )}
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Username"
              type="text"
              name="username"
              id="username"
              onChange={this.onChangeHandler}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Password"
              type="password"
              name="password"
              id="password"
              onChange={this.onChangeHandler}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Confirm password"
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              onChange={this.onChangeHandler}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Email"
              type="email"
              name="email"
              id="email"
              onChange={this.onChangeHandler}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Fullname"
              type="text"
              name="fullname"
              id="fullname"
              onChange={this.onChangeHandler}
              onKeyDown={this.onKeyDown}
            />
          </div>

          <div className="form-group">
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="role"
                id="radioUser"
                value="user"
                onClick={this.onChangeHandler}
              />
              <label className="form-check-label" htmlFor="radioUser">
                User
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="role"
                id="radioOwner"
                value="owner"
                onClick={this.onChangeHandler}
              />
              <label className="form-check-label" htmlFor="radioOwner">
                Owner
              </label>
            </div>
          </div>
          <div className="form-group">
            <button
              type="submit"
              id="register"
              className="btn btn-primary btn-block"
              onClick={this.register}
              disabled={this.state.loading}
            >
              Register
            </button>
          </div>
          <div className="form-group">
            <button
              type="submit"
              id="login"
              className="btn btn-info btn-block"
              onClick={() => this.redirect("/login")}
              disabled={this.state.loading}
            >
              Sign In
            </button>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    userData: state.user,
  };
}

export default withRouter(connect(mapStateToProps, {})(Register));
