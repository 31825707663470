import { IMeal } from "../interfaces/IMeal";
import Helper from "./helpers/helper";

const apiMeal = Helper.apiUrl + "meal";

class MealApi {
  static get(restaurantId: number) {
    return new Promise<IMeal[]>((resolve, reject) => {
      fetch(apiMeal + '/' + restaurantId)
        .then((res) => res.json())
        .then(
          (result: any) => {
            if (!result.error) {
              resolve(result);
            } else {
              reject(result.error);
            }
          },
          (error) => {
            reject(error.message);
          }
        );
      });
    }    

    static create(name: string, description: string, price:number, restaurantId:number) {
      return new Promise<IMeal>((resolve, reject) => {
        const confirmToken = Helper.getCookie(Helper.confirmationCookieName);
        fetch(apiMeal, {
          method: "POST",
          body: JSON.stringify({
            name,
            description,
            price,
            restaurantId,
            confirmToken
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then(
            (result: any) => {
              if (!result.error) {
                resolve(result);
              } else {
                reject(result.error);
              }
            },
            (error) => {
              reject(error.message);
            }
          );
      });
    }

    static update(id:number, name: string, description: string, price:number, restaurantId:number) {
      const confirmToken = Helper.getCookie(Helper.confirmationCookieName);
      return new Promise<IMeal>((resolve, reject) => {
        fetch(apiMeal + "/" + id, {
          method: "PUT",
          body: JSON.stringify({
            name,
            description,
            price,
            restaurantId,
            confirmToken
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then(
            (result: any) => {
              if (!result.error) {
                resolve(result);
              } else {
                reject(result.error);
              }
            },
            (error) => {
              reject(error.message);
            }
          );
      });
    }

    static delete(id:number) {
      const confirmToken = Helper.getCookie(Helper.confirmationCookieName);
      return new Promise<IMeal>((resolve, reject) => {
        fetch(apiMeal + "/" + id, {
          method: "DELETE", 
          body: JSON.stringify({
            confirmToken
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then(
            (result: any) => {
              if (!result.error) {
                resolve(result);
              } else {
                reject(result.error);
              }
            },
            (error) => {
              reject(error.message);
            }
          );
      });
    }
  }
  
  export default MealApi;
