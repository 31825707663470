import { Component } from "react";
import authApi from "../../api/authApi";
import Helper from "../../api/helpers/helper";

export interface IForgottenPasswordProps {
  history: any;
}
export interface IForgottenPasswordState {
  email: string;
  error: string;
  msg: string;
  loading: boolean;
}
export class ForgottenPassword extends Component<
  IForgottenPasswordProps,
  IForgottenPasswordState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      email: "",
      error: "",
      msg: "",
      loading: false,
    };
    this.resetPassword = this.resetPassword.bind(this);
  }
  componentDidMount() {}
  redirect = (path: string) => {
    this.props.history.push(path);
  };

  async resetPassword() {
    try {
      if (!this.state.email || !Helper.isValidEmail(this.state.email)) {
        this.setState({
          error: "Please provide valid email address!",
        });
        return;
      }
      this.setState({
        loading: true,
      });
      await authApi.forgottenPassword(this.state.email);
      this.setState({
        email: "",
        error: "",
        loading: false,
        msg:
          "Email for password reset is sent successfully. Please check your mailbox.",
      });
    } catch (err) {
      this.setState({
        error: err,
        loading: false,
      });
    }
  }
  onChangeHandler = (event: any) => {
    this.setState({
      email: event.target.value,
    });
  };
  onKeyDown = (event: any) => {
    if (event.key === "Enter" && !this.state.loading) {
      this.resetPassword();
    }
  };
  render() {
    return (
      <div className="forgotten-password">
        <div className="col-sm-12">
          <h2>Forgotten password</h2>
          {this.state.error && (
            <div id="error" className="text-danger">
              {this.state.error}
            </div>
          )}
          {this.state.msg && (
            <div id="msg" className="text-success">
              {this.state.msg}
            </div>
          )}
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Email address..."
              type="email"
              id="email"
              name="email"
              value={this.state.email}
              onChange={this.onChangeHandler}
              onKeyDown={this.onKeyDown}
            />
          </div>
          <div className="form-group">
            <button
              type="submit"
              id="reset"
              className="btn btn-primary btn-block"
              onClick={this.resetPassword}
              disabled={this.state.loading}
            >
              Reset password
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgottenPassword;
