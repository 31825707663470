import { IMeal } from "../interfaces/IMeal";
import { IOrder, IOrderDetails, IOrderMeal } from "../interfaces/IOrder";
import Helper from "./helpers/helper";

const apiOrder = Helper.apiUrl + "order";

class OrderApi {
  static own(isActive: string) {
    return new Promise<IOrder[]>((resolve, reject) => {
      fetch(apiOrder + '/own/' + isActive)
        .then((res) => res.json())
        .then(
          (result: any) => {
            if (!result.error) {
              resolve(result);
            } else {
              reject(result.error);
            }
          },
          (error) => {
            reject(error.message);
          }
        );
    });
  }    
  static place(restaurantId: number, meals: IOrderMeal[]) {
    return new Promise<IOrder>((resolve, reject) => {
      const confirmToken = Helper.getCookie(Helper.confirmationCookieName);
      fetch(apiOrder + '/place', {
        method: "POST",
        body: JSON.stringify({
          restaurantId,
          meals,
          confirmToken
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          (result: any) => {
            if (!result.error) {
              resolve(result);
            } else {
              reject(result.error);
            }
          },
          (error) => {
            reject(error.message);
          }
        );
    });
  }
  static proceed(orderId:number) {
    const confirmToken = Helper.getCookie(Helper.confirmationCookieName);
    return new Promise<IOrder>((resolve, reject) => {
      fetch(apiOrder + "/proceed", {
        method: "PUT",
        body: JSON.stringify({
          orderId,
          confirmToken
        }),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
        .then((res) => res.json())
        .then(
          (result: any) => {
            if (!result.error) {
              resolve(result);
            } else {
              reject(result.error);
            }
          },
          (error) => {
            reject(error.message);
          }
        );
    });
  }
  static get(id: number) {
    return new Promise<IOrderDetails>((resolve, reject) => {
      fetch(apiOrder + '/get/' + id)
        .then((res) => res.json())
        .then(
          (result: any) => {
            if (!result.error) {
              resolve(result);
            } else {
              reject(result.error);
            }
          },
          (error) => {
            reject(error.message);
          }
        );
      });
    }    
  }
  
  export default OrderApi;
