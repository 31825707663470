import Helper from "./helpers/helper";

const apiUser = Helper.apiUrl + "user";

class UserApi {
    static blocked(userId: number, restaurantId: number, blocked: string) {
      return new Promise<boolean>((resolve, reject) => {
        const confirmToken = Helper.getCookie(Helper.confirmationCookieName);
        fetch(apiUser + '/blocked', {
          method: "POST",
          body: JSON.stringify({
            userId,
            restaurantId,
            blocked,
            confirmToken
          }),
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
          .then((res) => res.json())
          .then(
            (result: any) => {
              if (!result.error) {
                resolve(result);
              } else {
                reject(result.error);
              }
            },
            (error) => {
              reject(error.message);
            }
          );
      });
    }
  }
  
  export default UserApi;
