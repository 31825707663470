import React, { Component, Fragment } from "react";
import "../../App.css";
import { connect } from "react-redux";
import authApi from "../../api/authApi";
import mealApi from "../../api/mealApi";
import orderApi from "../../api/orderApi";
import * as authAction from "../../actions/authAction";
import * as mealAction from "../../actions/mealAction";
import * as orderAction from "../../actions/orderAction";
import { IUser } from "../../interfaces/IUser";
import MealDetails from "./MealDetails";
import * as Icon from "react-bootstrap-icons";
import { Modal } from "react-bootstrap";
import { IMeal } from "../../interfaces/IMeal";
import { IOrder, IOrderMeal } from "../../interfaces/IOrder";
import { bindActionCreators } from "redux";
export interface IMenuProps {
  authAction: any;
  mealAction: any;
  orderAction: any;
  userData: IUser;
  mealData: IMeal[];
  history: any;
  match: any;
}
export interface IMenuState {
  search: string;
  newName: string;
  newDesc: string;
  newPrice: number;
  showAddModal: boolean;
  showOrderModal: boolean;
  errorAdd: string;
  restaurantId: number;
  orderMeals: IOrderMeal[];
  error: string;
  loading: boolean;
  loadingPost: boolean;
}
export class Menu extends Component<IMenuProps, IMenuState> {
  constructor(props: any) {
    super(props);
    this.state = {
      search: "",
      newName: "",
      newDesc: "",
      newPrice: 0,
      showAddModal: false,
      showOrderModal: false,
      errorAdd: "",
      restaurantId: -1,
      orderMeals: [],
      error: "",
      loading: false,
      loadingPost: false,
    };
    this.isLogged = this.isLogged.bind(this);
    this.getMeals = this.getMeals.bind(this);
    this.onMealCreate = this.onMealCreate.bind(this);
    this.onMealSave = this.onMealSave.bind(this);
    this.onMealDelete = this.onMealDelete.bind(this);
    this.placeOrder = this.placeOrder.bind(this);
  }
  componentDidMount() {
    const id = this.props.match.params.id;
    if (isNaN(id)) {
      this.redirect("/");
    }
    this.setState({
      restaurantId: id,
    });
    this.isLogged();
    this.getMeals(id);
  }
  redirect = (path: string) => {
    this.props.history.push(path);
  };
  onChangeHandler = (event: any) => {
    if (event.target.name == "newPrice") {
      if (event.target.value < 0) {
        return;
      }
    }
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  addToCart = (orderMeal: IOrderMeal) => {
    const tempOrderMeals = [...this.state.orderMeals];
    let index = tempOrderMeals.findIndex(
      (om) => om.meal_id === orderMeal.meal_id
    );
    if (index === -1) {
      tempOrderMeals.push(orderMeal);
    } else {
      tempOrderMeals[index].quantity += orderMeal.quantity;
    }
    this.setState({
      orderMeals: tempOrderMeals,
    });
  };
  removeFromCart = (meal_id: number) => {
    const tempOrderMeals = [...this.state.orderMeals];
    let index = tempOrderMeals.findIndex((om) => om.meal_id === meal_id);
    if (index !== -1) {
      tempOrderMeals.splice(index, 1);
    }
    this.setState({
      orderMeals: tempOrderMeals,
    });
  };

  handleAddModalShow = () => {
    this.setState({
      showAddModal: true,
    });
  };
  handleAddModalClose = () => {
    this.setState({
      showAddModal: false,
      newName: "",
      newDesc: "",
      newPrice: 0,
      errorAdd: "",
    });
  };
  handleOrderModalShow = () => {
    this.setState({
      showOrderModal: true,
    });
  };
  handleOrderModalClose = () => {
    this.setState({
      showOrderModal: false,
    });
  };

  async isLogged() {
    if (!this.props.userData.username || this.props.userData.username === "") {
      try {
        this.setState({
          loading: true,
        });
        const user = await authApi.getUserInfo();
        await this.props.authAction.login(user);
        this.setState({
          error: "",
          loading: false,
        });
      } catch (err) {
        this.redirect("/login");
      }
    }
  }
  async getMeals(id: number) {
    try {
      this.setState({
        loading: true,
      });
      const meals: IMeal[] = await mealApi.get(id);
      await this.props.mealAction.get(meals);
      this.setState({
        error: "",
        loading: false,
      });
    } catch (err) {
      this.setState({
        error: err,
        loading: false,
      });
    }
  }
  async onMealCreate() {
    if (this.state.newName.length < 3 || this.state.newDesc.length < 3) {
      this.setState({
        errorAdd:
          "Name and Description should be longer than 3 characters! Price is required!",
      });
      return;
    } else if (
      !this.state.newPrice ||
      this.state.newPrice == 0 ||
      isNaN(this.state.newPrice)
    ) {
      this.setState({
        errorAdd: "Please provide valid price!",
      });
      return;
    }
    try {
      this.setState({
        loadingPost: true,
      });
      const meal: IMeal = await mealApi.create(
        this.state.newName,
        this.state.newDesc,
        this.state.newPrice,
        this.state.restaurantId
      );
      await this.props.mealAction.create(meal);
      this.setState({
        error: "",
        loadingPost: false,
      });
    } catch (err) {
      this.setState({
        error: err,
        loadingPost: false,
      });
    }
    this.handleAddModalClose();
  }
  async onMealSave(
    id: number,
    name: string,
    description: string,
    price: number
  ) {
    try {
      const meal: IMeal = await mealApi.update(
        id,
        name,
        description,
        price,
        this.state.restaurantId
      );
      await this.props.mealAction.update(meal);
      this.setState({
        error: "",
      });
    } catch (err) {
      this.setState({
        error: err,
      });
    }
    this.handleAddModalClose();
  }
  async onMealDelete(id: number) {
    try {
      const meal: IMeal = await mealApi.delete(id);
      await this.props.mealAction.deleteItem(meal);
      this.setState({
        error: "",
      });
    } catch (err) {
      this.setState({
        error: err,
      });
    }
    this.handleAddModalClose();
  }

  async placeOrder() {
    try {
      this.setState({
        loadingPost: true,
      });
      const order: IOrder = await orderApi.place(
        this.state.restaurantId,
        this.state.orderMeals
      );
      await this.props.orderAction.place(order);
      this.setState({
        error: "",
        orderMeals: [],
        loadingPost: false,
      });
      this.redirect("/orders");
    } catch (err) {
      this.setState({
        error: err,
        loadingPost: false,
      });
    }
    this.handleOrderModalClose();
  }
  render() {
    let meals: IMeal[] = [];
    if (this.props.mealData.length > 0) {
      if (this.state.search != "") {
        this.props.mealData.map((m) => {
          if (
            m.name
              .toLocaleLowerCase()
              .includes(this.state.search.toLocaleLowerCase()) ||
            m.description
              .toLocaleLowerCase()
              .includes(this.state.search.toLocaleLowerCase())
          ) {
            meals.push(m);
          }
        });
      } else {
        meals = [...this.props.mealData];
      }
    }
    let total = 0;
    return (
      <Fragment>
        <div className="menu-container">
          <div className="menu-search">
            {this.props.userData.role === "owner" && (
              <button
                type="button"
                className="menu-button-add btn btn-primary"
                onClick={this.handleAddModalShow}
              >
                <Icon.PlusCircle /> Add Meal
              </button>
            )}
            {this.props.userData.role === "user" && (
              <button
                type="button"
                className="menu-button-cart btn btn-primary"
                onClick={this.handleOrderModalShow}
                disabled={this.state.orderMeals.length == 0}
              >
                {this.state.orderMeals.length > 0 ? (
                  <Fragment>
                    <Icon.CartFill className="md-icon" /> Finish Order
                  </Fragment>
                ) : (
                  <Fragment>
                    <Icon.Cart className="md-icon" /> Shopping Cart
                  </Fragment>
                )}
              </button>
            )}
            <input
              type="text"
              className="form-control"
              value={this.state.search}
              id="search"
              name="search"
              onChange={this.onChangeHandler}
              placeholder="Search..."
            />

            {this.state.error && (
              <div className="alert alert-danger">{this.state.error}</div>
            )}
          </div>
          {this.state.loading ? (
            <p className="text-center">
              <span className="spinner-border text-secondary" role="status">
                <span className="sr-only">Loading...</span>
              </span>
            </p>
          ) : (
            meals.map((m, index) => (
              <MealDetails
                onMealSave={this.onMealSave}
                onMealDelete={this.onMealDelete}
                addToCart={this.addToCart}
                key={index}
                meal={m}
                user={this.props.userData}
                history={this.props.history}
              />
            ))
          )}
        </div>
        <Modal show={this.state.showAddModal} onHide={this.handleAddModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Add Meal</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.errorAdd && (
              <div className="alert alert-danger">{this.state.errorAdd}</div>
            )}
            <input
              type="text"
              className="form-control meal-add-name"
              value={this.state.newName}
              name="newName"
              onChange={this.onChangeHandler}
              placeholder="Name..."
            />
            <textarea
              className="form-control meal-add-desc"
              placeholder="Description..."
              value={this.state.newDesc}
              name="newDesc"
              onChange={this.onChangeHandler}
              rows={2}
            ></textarea>
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text">$</span>
              </div>
              <input
                type="text"
                className="form-control meal-add-price"
                value={this.state.newPrice}
                name="newPrice"
                onChange={this.onChangeHandler}
                placeholder="Price..."
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={this.onMealCreate}
              disabled={this.state.loadingPost}
            >
              {this.state.loadingPost ? "Add Meal..." : "Add Meal"}
            </button>
            <button
              className="btn btn-secondary"
              onClick={this.handleAddModalClose}
              disabled={this.state.loadingPost}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showOrderModal}
          onHide={this.handleOrderModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Finish Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Fragment>
              {this.state.orderMeals.map((om, index) => {
                let sum = om.quantity * om.meal_price;
                total += sum;
                return (
                  <div className="row" key={index}>
                    <div className="col-sm-4">{om.meal_name}</div>
                    <div className="col-sm-2">${om.meal_price}</div>
                    <div className="col-sm-2">x{om.quantity}</div>
                    <div className="col-sm-2">${sum.toFixed(2)}</div>
                    <div className="col-sm-2">
                      <Icon.Trash
                        className="menu-remove-order-meal"
                        onClick={() => this.removeFromCart(om.meal_id)}
                      />
                    </div>
                  </div>
                );
              })}
              <hr />
              <div className="menu-totalsum">
                Total: ${parseFloat(total.toString()).toFixed(2)}
              </div>
            </Fragment>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              disabled={total == 0 || this.state.loadingPost}
              onClick={this.placeOrder}
            >
              {this.state.loadingPost ? "Order..." : "Order"}
            </button>
            <button
              className="btn btn-secondary"
              onClick={this.handleOrderModalClose}
              disabled={this.state.loadingPost}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    userData: state.user,
    mealData: state.meals,
  };
}
function mapDispatchToProps(dispatch: any) {
  return {
    authAction: bindActionCreators(authAction, dispatch),
    mealAction: bindActionCreators(mealAction, dispatch),
    orderAction: bindActionCreators(orderAction, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Menu);
