import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";

import "./App.css";
import NotFound from "./components/common/NotFound";
import Header from "./components/common/Header";
import Restaurant from "./components/restaurant/Restaurant";
import Login from "./components/auth/Login";
import Startup from "./components/Startup";
import Register from "./components/auth/Register";
import Menu from "./components/meal/Menu";
import Order from "./components/order/Order";
import OrderDetails from "./components/order/OrderDetails";
import ForgottenPassword from "./components/auth/ForgottenPassword";
import ResetPassword from "./components/auth/ResetPassword";
import App from "./App";

export default (
  <Fragment>
    <Startup>
      {/* <Header /> */}
      <Switch>
        <Route path="/" exact component={App} />
        {/* <Route path="/" exact component={Restaurant} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/orders" component={Order} />
        <Route path="/order/:id" component={OrderDetails} />
        <Route path="/menu/:id" component={Menu} />
        <Route path="/forgotten" component={ForgottenPassword} />
        <Route path="/reset/:token" component={ResetPassword} />
        <Route component={NotFound} /> */}
      </Switch>
    </Startup>
  </Fragment>
);
