import React, { Component, Fragment } from "react";
import "../../App.css";
import { connect } from "react-redux";
import { IUser } from "../../interfaces/IUser";
import authApi from "../../api/authApi";
import restaurantApi from "../../api/restaurantApi";
import * as authAction from "../../actions/authAction";
import * as restaurantAction from "../../actions/restaurantAction";
import RestaurantDetails from "./RestaurantDetails";
import { IRestaurant } from "../../interfaces/IRestaurant";
import * as Icon from "react-bootstrap-icons";
import { Modal } from "react-bootstrap";
import { bindActionCreators } from "redux";
export interface IRestaurantProps {
  authAction: any;
  restaurantAction: any;
  userData: IUser;
  restaurantData: IRestaurant[];
  history: any;
}
export interface IRestaurantState {
  search: string;
  newName: string;
  newDesc: string;
  showCreationModal: boolean;
  errorAdd: string;
  error: string;
  loading: boolean;
  loadingPost: boolean;
}

export class Restaurant extends Component<IRestaurantProps, IRestaurantState> {
  constructor(props: any) {
    super(props);
    this.state = {
      search: "",
      newName: "",
      newDesc: "",
      showCreationModal: false,
      errorAdd: "",
      error: "",
      loading: false,
      loadingPost: false,
    };
    this.isLogged = this.isLogged.bind(this);
    this.getRestaurants = this.getRestaurants.bind(this);
    this.onRestaurantCreate = this.onRestaurantCreate.bind(this);
    this.onRestaurantSave = this.onRestaurantSave.bind(this);
    this.onRestaurantDelete = this.onRestaurantDelete.bind(this);
  }
  componentDidMount() {
    this.isLogged();
    this.getRestaurants();
  }
  redirect = (path: string) => {
    this.props.history.push(path);
  };
  handleModalShow = () => {
    this.setState({
      showCreationModal: true,
    });
  };
  handleModalClose = () => {
    this.setState({
      showCreationModal: false,
      newName: "",
      newDesc: "",
      errorAdd: "",
    });
  };
  onChangeHandler = (event: any) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  async isLogged() {
    if (!this.props.userData.username || this.props.userData.username === "") {
      try {
        this.setState({
          loading: true,
        });
        const user = await authApi.getUserInfo();
        await this.props.authAction.login(user);
        this.setState({
          error: "",
          loading: false,
        });
      } catch (err) {
        this.redirect("/login");
      }
    }
  }
  async getRestaurants() {
    try {
      this.setState({
        loading: true,
      });
      const restaurants: IRestaurant[] = await restaurantApi.get();
      await this.props.restaurantAction.get(restaurants);
      this.setState({
        error: "",
        loading: false,
      });
    } catch (err) {
      this.setState({
        error: err,
        loading: false,
      });
    }
  }
  async onRestaurantCreate() {
    if (this.state.newName.length < 3 || this.state.newDesc.length < 3) {
      this.setState({
        errorAdd: "Name and Description should be longer than 3 characters!",
      });
      return;
    }
    try {
      this.setState({
        loadingPost: true,
      });
      const restaurant: IRestaurant = await restaurantApi.create(
        this.state.newName,
        this.state.newDesc
      );
      await this.props.restaurantAction.create(restaurant);
      this.setState({
        error: "",
        loadingPost: false,
      });
    } catch (err) {
      this.setState({
        error: err,
        loadingPost: false,
      });
    }
    this.handleModalClose();
  }
  async onRestaurantSave(id: number, name: string, description: string) {
    try {
      const restaurant = await restaurantApi.update(id, name, description);
      await this.props.restaurantAction.update(restaurant);
      this.setState({
        error: "",
      });
    } catch (err) {
      this.setState({
        error: err,
      });
    }
    this.handleModalClose();
  }
  async onRestaurantDelete(id: number) {
    try {
      const restaurant = await restaurantApi.delete(id);
      await this.props.restaurantAction.deleteItem(restaurant);
      this.setState({
        error: "",
      });
    } catch (err) {
      this.setState({
        error: err,
      });
    }
    this.handleModalClose();
  }

  render() {
    let restaurants: IRestaurant[] = [];
    if (this.props.restaurantData.length > 0) {
      if (this.state.search != "") {
        this.props.restaurantData.map((r) => {
          if (
            r.name
              .toLocaleLowerCase()
              .includes(this.state.search.toLocaleLowerCase()) ||
            r.description
              .toLocaleLowerCase()
              .includes(this.state.search.toLocaleLowerCase())
          ) {
            restaurants.push(r);
          }
        });
      } else {
        restaurants = [...this.props.restaurantData];
      }
    }
    return (
      <Fragment>
        <div className="restaurant-container">
          <div className="restaurant-search">
            {this.props.userData.role === "owner" && (
              <button
                type="button"
                className="restaurant-button-add btn btn-primary"
                onClick={this.handleModalShow}
              >
                <Icon.PlusCircle /> Add Restaurant
              </button>
            )}
            <input
              type="text"
              className="form-control"
              value={this.state.search}
              name="search"
              id="search"
              onChange={this.onChangeHandler}
              placeholder="Search..."
            />
            {this.state.error && (
              <div className="alert alert-danger">{this.state.error}</div>
            )}
          </div>
          {this.state.loading ? (
            <p className="text-center">
              <span className="spinner-border text-secondary" role="status">
                <span className="sr-only">Loading...</span>
              </span>
            </p>
          ) : (
            restaurants.map((r, index) => (
              <RestaurantDetails
                onRestaurantSave={this.onRestaurantSave}
                onRestaurantDelete={this.onRestaurantDelete}
                key={index}
                restaurant={r}
                user={this.props.userData}
                history={this.props.history}
              />
            ))
          )}
        </div>
        <Modal
          show={this.state.showCreationModal}
          onHide={this.handleModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Add restaurant</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {this.state.errorAdd && (
              <div className="alert alert-danger">{this.state.errorAdd}</div>
            )}
            <input
              type="text"
              className="form-control restaurant-add-name"
              value={this.state.newName}
              name="newName"
              onChange={this.onChangeHandler}
              placeholder="Name..."
            />
            <textarea
              className="form-control restaurant-add-desc"
              placeholder="Cuisine..."
              value={this.state.newDesc}
              name="newDesc"
              onChange={this.onChangeHandler}
              rows={2}
            ></textarea>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={this.onRestaurantCreate}
              disabled={this.state.loadingPost}
            >
              {this.state.loadingPost ? "Add Restaurant..." : "Add Restaurant"}
            </button>
            <button
              className="btn btn-secondary"
              onClick={this.handleModalClose}
              disabled={this.state.loadingPost}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    userData: state.user,
    restaurantData: state.restaurants,
  };
}
function mapDispatchToProps(dispatch: any) {
  return {
    authAction: bindActionCreators(authAction, dispatch),
    restaurantAction: bindActionCreators(restaurantAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Restaurant);
