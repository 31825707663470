import { Component } from "react";
import { connect } from "react-redux";
import { Link, NavLink, withRouter } from "react-router-dom";
import * as authAction from "../../actions/authAction";
import authApi from "../../api/authApi";
import { bindActionCreators } from "redux";
import { IUser, IUserLogin } from "../../interfaces/IUser";

export interface ILoginProps {
  authAction: any;
  userData: IUser;
  history: any;
}
export interface ILoginState {
  username: string;
  password: string;
  error: string;
  loading: boolean;
}
export class Login extends Component<ILoginProps, ILoginState> {
  constructor(props: any) {
    super(props);

    this.state = {
      username: "",
      password: "",
      error: "",
      loading: false,
    };

    this.login = this.login.bind(this);
  }
  componentDidMount() {
    if (this.props.userData.username) {
      this.redirect("/");
    }
  }
  redirect = (path: string) => {
    this.props.history.push(path);
  };
  async login() {
    try {
      if (!this.state.username || !this.state.password) {
        this.setState({
          error: "All fields are required!",
        });
        return;
      }
      this.setState({
        loading: true,
      });
      const userLogin: IUserLogin = {
        username: this.state.username,
        password: this.state.password,
      };
      const user = await authApi.login(userLogin);
      await this.props.authAction.login(user);
      this.setState({
        error: "",
        loading: false,
      });
      this.redirect("/");
    } catch (err) {
      this.setState({
        error: err,
        loading: false,
      });
    }
  }
  onChangeHandler = (event: any) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };
  onKeyDownPassword = (event: any) => {
    if (event.key === "Enter" && !this.state.loading) {
      this.login();
    }
  };
  render() {
    return (
      <div className="register-login">
        <div className="col-sm-12">
          <h2>Log in</h2>
          {this.state.error && (
            <div id="error" className="text-danger">
              {this.state.error}
            </div>
          )}
          <div className="form-group">
            <input
              className="form-control"
              placeholder="username"
              type="text"
              id="username"
              name="username"
              onChange={this.onChangeHandler}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Password"
              type="password"
              id="password"
              name="password"
              onChange={this.onChangeHandler}
              onKeyDown={this.onKeyDownPassword}
            />
          </div>
          <div className="form-group">
            <button
              type="submit"
              id="login"
              className="btn btn-primary btn-block"
              onClick={this.login}
              disabled={this.state.loading}
            >
              Log in
            </button>
          </div>
          <div className="form-group">
            <button
              type="submit"
              id="register"
              className="btn btn-info btn-block"
              onClick={() => this.redirect("/register")}
              disabled={this.state.loading}
            >
              Sign Up
            </button>
          </div>
          <div className="forgotenPasswordLable">
            <Link to={`/forgotten`}>Forgotten password</Link>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    userData: state.user,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    authAction: bindActionCreators(authAction, dispatch),
  };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));
