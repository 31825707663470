import { IUser } from "../interfaces/IUser";
import * as types from "./actionTypes";

export const login = (user: IUser) => {
  return {
    type: types.LOGIN_USER_SUCCESS,
    user,
  };
};
export const logout = () => {
  return {
    type: types.LOGOUT_SUCCESS,
  };
};