import { Component } from "react";
import authApi from "../../api/authApi";
import Helper from "../../api/helpers/helper";

export interface IResetPasswordProps {
  history: any;
  match: any;
}
export interface IResetPasswordState {
  password: string;
  confirmPassword: string;
  token: string;
  error: string;
  loading: boolean;
}
export class ResetPassword extends Component<
  IResetPasswordProps,
  IResetPasswordState
> {
  constructor(props: any) {
    super(props);

    this.state = {
      password: "",
      confirmPassword: "",
      token: "",
      error: "",
      loading: false,
    };
    this.reset = this.reset.bind(this);
  }
  componentDidMount() {
    const token = this.props.match.params.token;
    if (!token) {
      this.redirect("/login");
    }
    this.setState({
      token: token,
    });
  }
  redirect = (path: string) => {
    this.props.history.push(path);
  };

  async reset() {
    try {
      if (!this.state.password || this.state.password.length < 3) {
        this.setState({
          error: "Please provide password with min lenght of 3 characters!",
        });
        return;
      }
      if (this.state.password !== this.state.confirmPassword) {
        this.setState({
          error: "Password and Confirm password must match!",
        });
        return;
      }
      this.setState({
        loading: true,
      });
      await authApi.resetPassword(this.state.token, this.state.password);
      this.setState({
        error: "",
        loading: false,
      });
      this.redirect("/login");
    } catch (err) {
      this.setState({
        error: err,
        loading: false,
      });
    }
  }
  onChangeHandler = (event: any) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };
  onKeyDown = (event: any) => {
    if (event.key === "Enter" && !this.state.loading) {
      this.reset();
    }
  };
  render() {
    return (
      <div className="forgotten-password">
        <div className="col-sm-12">
          <h2>Change password</h2>
          {this.state.error && (
            <div id="error" className="text-danger">
              {this.state.error}
            </div>
          )}
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Password"
              type="password"
              id="password"
              name="password"
              onChange={this.onChangeHandler}
            />
          </div>
          <div className="form-group">
            <input
              className="form-control"
              placeholder="Confirm password"
              type="password"
              id="confirmPassword"
              name="confirmPassword"
              onChange={this.onChangeHandler}
              onKeyDown={this.onKeyDown}
            />
          </div>
          <div className="form-group">
            <button
              type="submit"
              id="reset"
              className="btn btn-primary btn-block"
              onClick={this.reset}
              disabled={this.state.loading}
            >
              Change Password
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ResetPassword;
