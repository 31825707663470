import initialStateAuth from "./initialStateAuth";
import * as types from "../actions/actionTypes";

const userReducer = (state = initialStateAuth, action: any) => {
  switch (action.type) {
    case types.LOGIN_USER_SUCCESS:
      return Object.assign({}, state, {
        ...action.user,
      });
    case types.LOGOUT_SUCCESS:
      return Object.assign({}, state, {
        ...initialStateAuth,
      });
    default:
      return state;
  }
};
export default userReducer;
