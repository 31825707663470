class Helper {
    static apiUrl = window.location.protocol + "//" +(process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : "") + "/";
    static confirmationCookieName =  (process.env.REACT_APP_CONFIRMATION_COOKIE_NAME ? process.env.REACT_APP_CONFIRMATION_COOKIE_NAME : "");
    static getCookie(name: string) {
      const nameEQ = name + "=";
      const ca = document.cookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
      }
      return null;
    }
    static isValidEmail(email: string){
        let regEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if(regEmail.test(email)){
          return true;
        }
        return false;
    }
}
export default Helper;