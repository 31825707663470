import { IOrder, IOrderDetails } from "../interfaces/IOrder";
import * as types from "./actionTypes";

export const own = (orders: IOrder[]) => {
  return {
    type: types.GET_OWN_SUCCESS,
    orders,
  };
};
export const place = (order: IOrder) => {
  return {
    type: types.PLACE_ORDER_SUCCESS,
    order,
  };
};
export const proceed = (order: IOrder) => {
  return {
    type: types.PROCEED_ORDER_SUCCESS,
    order,
  };
};
export const get = (orderDetails: IOrderDetails) => {  
  return {
    type: types.GET_ORDER_DETAILS_SUCCESS,
    orderDetails,
  };
};