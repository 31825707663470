import * as types from "../actions/actionTypes";
import initialStateOrderDetails from "./initialStateOrderDetails";

const orderDetailsReducer = (state = initialStateOrderDetails, action: any) => {
  switch (action.type) {
    case types.GET_ORDER_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        ...action.orderDetails,
      });
    default:
      return state;
  }
};
export default orderDetailsReducer;
