import * as types from "../actions/actionTypes";
import initialStateOrder from "./initialStateOrder";
import { IOrder } from "../interfaces/IOrder";

const orderReducer = (state = initialStateOrder, action: any) => {
  switch (action.type) {
    case types.GET_OWN_SUCCESS:
      return Object.assign([], action.orders);
    case types.PLACE_ORDER_SUCCESS:
      let newState : IOrder[] = [...state];
      newState.push(action.order);
      return Object.assign([], newState);
    case types.PROCEED_ORDER_SUCCESS:
      let updateState : IOrder[] = [];
      state.map((o:IOrder) => {
        if(o.id == action.order.id){
          o = {...action.order}
        }
        updateState.push(o);
      })
      return Object.assign([], updateState)
    default:
      return state;
  }
};
export default orderReducer;
