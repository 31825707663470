import * as types from "../actions/actionTypes";
import initialStateMeal from "./initialStateMeal";
import { IMeal } from "../interfaces/IMeal";

const mealReducer = (state = initialStateMeal, action: any) => {
  switch (action.type) {
    case types.GET_MEALS_SUCCESS:
      return Object.assign([], action.meals);
    case types.CREATE_MEAL_SUCCESS:
      let newState : IMeal[] = [...state];
      newState.push(action.meal);
      return Object.assign([], newState);
    case types.UPDATE_MEAL_SUCCESS:
      let updateState : IMeal[] = [];
      state.map((m:IMeal) => {
        if(m.id == action.meal.id){
          m = { ...action.meal }
        }
        updateState.push(m);
      })
      return Object.assign([], updateState)
    case types.DELETE_MEAL_SUCCESS:
      return Object.assign([], [...state.filter((r: IMeal) => r.id != action.meal.id)]);
    default:
      return state;
  }
};
export default mealReducer;
