import * as types from "../actions/actionTypes";
import initialStateRestaurant from "./initialStateRestaurant";
import { IRestaurant } from "../interfaces/IRestaurant";

const restaurantReducer = (state = initialStateRestaurant, action: any) => {
  switch (action.type) {
    case types.GET_RESTAURANTS_SUCCESS:
      return Object.assign([], action.restaurants);
    case types.CREATE_RESTAURANT_SUCCESS:
      let newState : IRestaurant[] = [...state];
      newState.push(action.restaurant);
      return Object.assign([], newState);
    case types.UPDATE_RESTAURANT_SUCCESS:
      let updateState : IRestaurant[] = [];
      state.map((r:IRestaurant) => {
        if(r.id == action.restaurant.id){
          r = {...action.restaurant}
        }
        updateState.push(r);
      })
      return Object.assign([], updateState)
    case types.DELETE_RESTAURANT_SUCCESS:
      return Object.assign([], [...state.filter((r: IRestaurant) => r.id != action.restaurant.id)]);
    default:
      return state;
  }
};
export default restaurantReducer;
