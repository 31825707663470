import React, { Component, Fragment } from "react";
import "../../App.css";
import { connect } from "react-redux";
import { IUser } from "../../interfaces/IUser";
import authApi from "../../api/authApi";
import * as authAction from "../../actions/authAction";
import orderApi from "../../api/orderApi";
import * as orderAction from "../../actions/orderAction";
import { IOrder } from "../../interfaces/IOrder";
import OrderListDetails from "./OrderListDetails";
import { bindActionCreators } from "redux";
export interface IOrderProps {
  authAction: any;
  orderAction: any;
  userData: IUser;
  orderData: IOrder[];
  history: any;
}
export interface IOrderState {
  search: string;
  orders: IOrder[];
  showCreationModal: boolean;
  error: string;
  isActive: string;
  loading: boolean;
}

export class Order extends Component<IOrderProps, IOrderState> {
  constructor(props: any) {
    super(props);
    this.state = {
      search: "",
      orders: [],
      showCreationModal: false,
      error: "",
      isActive: "active",
      loading: false,
    };
    this.isLogged = this.isLogged.bind(this);
    this.getOwn = this.getOwn.bind(this);
    this.proceedOrder = this.proceedOrder.bind(this);
  }
  componentDidMount() {
    this.isLogged();
    this.getOwn(this.state.isActive);
  }
  redirect = (path: string) => {
    this.props.history.push(path);
  };
  onChangeHandler = (event: any) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };
  onChangeActive = (event: any) => {
    let isActive = event.target.value;
    this.getOwn(isActive);
    this.setState({
      isActive,
    });
  };
  async isLogged() {
    if (!this.props.userData.username || this.props.userData.username === "") {
      try {
        this.setState({
          loading: true,
        });
        const user = await authApi.getUserInfo();
        await this.props.authAction.login(user);
        this.setState({
          error: "",
          loading: false,
        });
      } catch (err) {
        this.redirect("/login");
      }
    }
  }
  async getOwn(isActive: string) {
    try {
      this.setState({
        loading: true,
      });
      const orders: IOrder[] = await orderApi.own(isActive);
      await this.props.orderAction.own(orders);
      this.setState({
        error: "",
        loading: false,
      });
    } catch (err) {
      this.setState({
        error: err,
        loading: false,
      });
    }
  }

  async proceedOrder(id: number) {
    try {
      const order: IOrder = await orderApi.proceed(id);
      await this.props.orderAction.proceed(order);
      this.setState({
        error: "",
      });
    } catch (err) {
      this.setState({
        error: err,
      });
    }
  }

  render() {
    let orders: IOrder[] = [];
    if (this.props.orderData.length > 0) {
      if (this.state.search != "") {
        this.props.orderData.map((o) => {
          if (
            o.restaurant_name
              .toLocaleLowerCase()
              .includes(this.state.search.toLocaleLowerCase()) ||
            o.status_title
              .toLocaleLowerCase()
              .includes(this.state.search.toLocaleLowerCase()) ||
            o.user_fullname
              .toLocaleLowerCase()
              .includes(this.state.search.toLocaleLowerCase()) ||
            o.user_username
              .toLocaleLowerCase()
              .includes(this.state.search.toLocaleLowerCase())
          ) {
            if (
              (this.state.isActive == "active" && o.status_last_element == 0) ||
              (this.state.isActive == "inactive" && o.status_last_element == 1)
            ) {
              orders.push(o);
            }
          }
        });
      } else {
        this.props.orderData.map((o) => {
          if (
            (this.state.isActive == "active" && o.status_last_element == 0) ||
            (this.state.isActive == "inactive" && o.status_last_element == 1)
          ) {
            orders.push(o);
          }
        });
      }
    }
    return (
      <Fragment>
        <div className="order-container">
          <div className="order-search">
            <div
              className="order-active-btn btn-group btn-group-toggle"
              data-toggle="buttons"
              onChange={this.onChangeActive}
            >
              <label
                className={
                  this.state.isActive == "active"
                    ? "btn btn-light active"
                    : "btn btn-light"
                }
              >
                <input type="radio" name="isActive" value="active" />
                Active Orders
              </label>
              <label
                className={
                  this.state.isActive != "active"
                    ? "btn btn-light active"
                    : "btn btn-light"
                }
              >
                <input type="radio" name="isActive" value="inactive" />
                Archived Orders
              </label>
            </div>
            <input
              type="text"
              className="form-control"
              value={this.state.search}
              name="search"
              id="search"
              onChange={this.onChangeHandler}
              placeholder="Search..."
            />
            {this.state.error && (
              <div className="alert alert-danger">{this.state.error}</div>
            )}
          </div>
          {this.state.loading ? (
            <p className="text-center">
              <span className="spinner-border text-secondary" role="status">
                <span className="sr-only">Loading...</span>
              </span>
            </p>
          ) : (
            orders.map((o, index) => (
              <OrderListDetails
                userData={this.props.userData}
                key={index}
                order={o}
                history={this.props.history}
                proceedOrder={this.proceedOrder}
              />
            ))
          )}
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    userData: state.user,
    orderData: state.orders,
  };
}
function mapDispatchToProps(dispatch: any) {
  return {
    authAction: bindActionCreators(authAction, dispatch),
    orderAction: bindActionCreators(orderAction, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Order);
