import { IMeal } from "../interfaces/IMeal";
import * as types from "./actionTypes";

export const get = (meals: IMeal[]) => {
  return {
    type: types.GET_MEALS_SUCCESS,
    meals,
  };
};
export const create = (meal: IMeal) => {
  return {
    type: types.CREATE_MEAL_SUCCESS,
    meal,
  };
};
export const update = (meal: IMeal) => {
  return {
    type: types.UPDATE_MEAL_SUCCESS,
    meal,
  };
};
export const deleteItem = (meal: IMeal) => {
  return {
    type: types.DELETE_MEAL_SUCCESS,
    meal,
  };
};