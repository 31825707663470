import React, { Component, Fragment } from "react";
import * as Icon from "react-bootstrap-icons";
import "../../App.css";
import { IRestaurant } from "../../interfaces/IRestaurant";
import { IUser } from "../../interfaces/IUser";
import { Modal } from "react-bootstrap";
export interface IRestaurantDetailsProps {
  history: any;
  user: IUser;
  restaurant: IRestaurant;
  onRestaurantSave: any;
  onRestaurantDelete: any;
}
export interface IRestaurantDetailsState {
  edit: boolean;
  editId: number;
  editName: string;
  editDesc: string;
  showDeletionModal: boolean;
  errorEdit: string;
  loading: boolean;
}

export class RestaurantDetails extends Component<
  IRestaurantDetailsProps,
  IRestaurantDetailsState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      edit: false,
      editId: 0,
      editName: "",
      editDesc: "",
      showDeletionModal: false,
      errorEdit: "",
      loading: false,
    };
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }
  redirect = (path: string) => {
    this.props.history.push(path);
  };

  onChangeHandler = (event: any) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  openEdit = () => {
    this.setState({
      edit: true,
      editId: this.props.restaurant.id,
      editName: this.props.restaurant.name,
      editDesc: this.props.restaurant.description,
    });
  };

  closeEdit = () => {
    this.setState({
      edit: false,
      errorEdit: "",
    });
  };

  handleModalShow = () => {
    this.setState({
      showDeletionModal: true,
    });
  };
  handleModalClose = () => {
    this.setState({
      showDeletionModal: false,
    });
  };

  async onSave() {
    if (this.state.editName.length < 3 || this.state.editDesc.length < 3) {
      this.setState({
        errorEdit: "Name and Description should be longer than 3 characters!",
      });
      return;
    }
    this.setState({
      loading: true,
    });
    await this.props.onRestaurantSave(
      this.state.editId,
      this.state.editName,
      this.state.editDesc
    );
    this.setState({
      edit: false,
      errorEdit: "",
      loading: false,
    });
  }

  async onDelete() {
    this.setState({
      loading: true,
    });
    await this.props.onRestaurantDelete(this.props.restaurant.id);
    this.setState({
      loading: false,
    });
    this.handleModalClose();
  }

  render() {
    return (
      <div className="restaurant-detail row">
        <div className="col-sm-1">
          <Icon.Shop className="rd-shop-icon" />
        </div>
        <div className="col-sm-9">
          {this.state.errorEdit && (
            <div className="alert alert-danger">{this.state.errorEdit}</div>
          )}
          <div className="rd-name">
            {this.state.edit ? (
              <div className="">
                <input
                  type="text"
                  className="form-control rd-edit-name"
                  value={this.state.editName}
                  name="editName"
                  onChange={this.onChangeHandler}
                  placeholder="Name..."
                />
              </div>
            ) : (
              this.props.restaurant.name
            )}
          </div>
          <div className="rd-desc">
            <span className="rd-desc-title">Cuisine: </span>
            {this.state.edit ? (
              <div className="">
                <textarea
                  className="form-control rd-edit-desc"
                  value={this.state.editDesc}
                  name="editDesc"
                  onChange={this.onChangeHandler}
                  placeholder="Cuisine..."
                  rows={2}
                ></textarea>
              </div>
            ) : (
              this.props.restaurant.description
            )}
          </div>
        </div>
        <div className="col-sm-2 rd-button-container">
          {this.props.user.role === "owner" ? (
            <Fragment>
              {this.state.edit ? (
                <Fragment>
                  <button
                    type="button"
                    className="rd-button btn btn-success"
                    onClick={this.onSave}
                    disabled={this.state.loading}
                  >
                    <Icon.Save /> {this.state.loading ? "Save..." : "Save"}
                  </button>
                  <button
                    type="button"
                    className="rd-button btn btn-danger"
                    onClick={this.closeEdit}
                    disabled={this.state.loading}
                  >
                    <Icon.XOctagon /> Close
                  </button>
                </Fragment>
              ) : (
                <Fragment>
                  <Icon.PencilSquare
                    className="rd-button-icon-edit"
                    onClick={this.openEdit}
                  />
                  <Icon.Trash
                    className="rd-button-icon-delete"
                    onClick={this.handleModalShow}
                  />
                  <button
                    type="button"
                    className="rd-button btn btn-primary"
                    onClick={() =>
                      this.redirect("/menu/" + this.props.restaurant.id)
                    }
                  >
                    <Icon.MenuUp /> Menu
                  </button>
                </Fragment>
              )}
            </Fragment>
          ) : this.props.restaurant.isBlocked === 1 ? (
            <Fragment>
              <button
                type="button"
                disabled
                className="rd-button btn btn-secondary"
                onClick={() =>
                  this.redirect("/menu/" + this.props.restaurant.id)
                }
              >
                <Icon.MenuUp /> Menu
              </button>
              <div>Not available!</div>
            </Fragment>
          ) : (
            <button
              type="button"
              className="rd-button-user btn btn-primary"
              onClick={() => this.redirect("/menu/" + this.props.restaurant.id)}
            >
              <Icon.MenuUp /> Menu
            </button>
          )}
        </div>
        <Modal
          show={this.state.showDeletionModal}
          onHide={this.handleModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Restaurant deletion!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete restaurant:{" "}
            {this.props.restaurant.name}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={this.handleModalClose}
              disabled={this.state.loading}
            >
              Cancel
            </button>
            <button
              className="btn btn-danger"
              onClick={this.onDelete}
              disabled={this.state.loading}
            >
              {this.state.loading ? "Delete..." : "Delete"}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default RestaurantDetails;
