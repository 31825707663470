import React, { Component, Fragment } from "react";
import { Modal } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";
import "../../App.css";
import { IOrder } from "../../interfaces/IOrder";
import { IUser } from "../../interfaces/IUser";
export interface IOrderListDetailsProps {
  history: any;
  userData: IUser;
  order: IOrder;
  proceedOrder: any;
}
export interface IOrderDetailsState {
  showModal: boolean;
  loading: boolean;
}

export class OrderListDetails extends Component<
  IOrderListDetailsProps,
  IOrderDetailsState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      showModal: false,
      loading: false,
    };
    this.onProceedOrder = this.onProceedOrder.bind(this);
  }
  redirect = (path: string) => {
    this.props.history.push(path);
  };
  handleModalShow = () => {
    this.setState({
      showModal: true,
    });
  };
  handleModalClose = () => {
    this.setState({
      showModal: false,
    });
  };
  async onProceedOrder() {
    this.setState({
      loading: true,
    });
    await this.props.proceedOrder(this.props.order.id);
    this.setState({
      loading: false,
    });
    this.handleModalClose();
  }

  render() {
    let proceedOrder = <div></div>;
    if (this.props.order.status_last_element == 1) {
      proceedOrder = (
        <button
          type="button"
          id="proceedOrder"
          disabled
          className="od-button btn btn-secondary"
        >
          <Icon.FileZip /> {this.props.order.status_title}
        </button>
      );
    } else if (
      this.props.userData.role === "user" &&
      this.props.order.status_name !== "placed"
    ) {
      proceedOrder = (
        <button
          type="button"
          id="proceedOrder"
          disabled
          className="od-button btn btn-secondary"
        >
          <Icon.BoxArrowInRight /> {this.props.order.status_title}
        </button>
      );
    } else if (
      this.props.userData.role === "user" &&
      this.props.order.status_name === "placed"
    ) {
      proceedOrder = (
        <button
          type="button"
          id="proceedOrder"
          className="od-button btn btn-danger"
          disabled={this.state.loading}
          onClick={this.handleModalShow}
        >
          <Icon.XOctagon />{" "}
          {this.state.loading ? "Cancel Order..." : "Cancel Order"}
        </button>
      );
    } else {
      proceedOrder = (
        <button
          type="button"
          id="proceedOrder"
          className="od-button btn btn-primary"
          disabled={this.state.loading}
          onClick={this.handleModalShow}
        >
          <Icon.BoxArrowInRight />
          {this.state.loading ? "Proceed Order..." : "Proceed Order"}
        </button>
      );
    }
    var date = new Date(
      this.props.order.datetime.replace(/-/g, "/")
    ).toLocaleDateString("EN-US");
    return (
      <Fragment>
        <div className="order-detail row">
          <div className="col-sm-1">
            <Icon.BagCheck className="od-shop-icon" />
          </div>
          <div className="col-sm-2">
            <div className="od-field">
              <div className="od-field-title">Restaurant</div>
              <div id="od-restaurant-name" className="od-field-value">
                {this.props.order.restaurant_name}
              </div>
            </div>
          </div>
          <div className="col-sm-1">
            <div className="od-field">
              <div className="od-field-title">Client</div>
              <div id="od-restaurant-user-fullname" className="od-field-value">
                {this.props.order.user_fullname}
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="od-field">
              <div className="od-field-title">Date</div>
              <div id="od-restaurant-date" className="od-field-value">
                {date}
              </div>
            </div>
          </div>
          <div className="col-sm-1">
            <div className="od-field">
              <div className="od-field-title">Total</div>
              <div id="od-restaurant-totalsum" className="od-field-value">
                ${this.props.order.totalsum}
              </div>
            </div>
          </div>
          <div className="col-sm-2">
            <div className="od-field">
              <div className="od-field-title">Status</div>
              <div id="od-restaurant-status" className="od-field-value">
                {this.props.order.status_title}
              </div>
            </div>
          </div>
          <div className="col-sm-3 od-button-container">
            <div>
              <button
                type="button"
                className="od-button btn btn-info"
                id="showDetails"
                onClick={() => this.redirect("/order/" + this.props.order.id)}
              >
                <Icon.Journals /> Show Details
              </button>
            </div>
            <div>{proceedOrder}</div>
          </div>
        </div>
        <Modal show={this.state.showModal} onHide={this.handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to proceed.</Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-primary"
              onClick={this.onProceedOrder}
              disabled={this.state.loading}
            >
              {this.state.loading ? "Proceed..." : "Proceed"}
            </button>
            <button
              className="btn btn-secondary"
              onClick={this.handleModalClose}
              disabled={this.state.loading}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

export default OrderListDetails;
