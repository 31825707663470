import { IRestaurant } from "../interfaces/IRestaurant";
import * as types from "./actionTypes";

export const get = (restaurants: IRestaurant[]) => {
  return {
    type: types.GET_RESTAURANTS_SUCCESS,
    restaurants,
  };
};
export const create = (restaurant: IRestaurant) => {
  return {
    type: types.CREATE_RESTAURANT_SUCCESS,
    restaurant,
  };
};
export const update = (restaurant: IRestaurant) => {
  return {
    type: types.UPDATE_RESTAURANT_SUCCESS,
    restaurant,
  };
};
export const deleteItem = (restaurant: IRestaurant) => {
  return {
    type: types.DELETE_RESTAURANT_SUCCESS,
    restaurant,
  };
};