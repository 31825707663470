import { combineReducers } from "redux";
import auth from "./authReducer";
import restaurants from "./restaurantReducer";
import meals from "./mealReducer";
import orders from "./orderReducer";
import orderDetails from "./orderDetailsReducer";
import { withReduxStateSync } from "redux-state-sync";

const rootReducer = combineReducers({
  user: auth,
  restaurants: restaurants,
  meals: meals,
  orders: orders,
  orderDetails: orderDetails,
});

export default withReduxStateSync(rootReducer);
