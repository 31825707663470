import React, { Component } from "react";

class NotFound extends Component {
  render() {
    return (
      <div>
        <div className="pageNotFound">Page not found!</div>
      </div>
    );
  }
}

export default NotFound;
