import React, { Component, Fragment } from "react";
import "../../App.css";
import { connect } from "react-redux";
import { IUser } from "../../interfaces/IUser";
import { IOrderDetails } from "../../interfaces/IOrder";
import authApi from "../../api/authApi";
import * as authAction from "../../actions/authAction";
import * as orderAction from "../../actions/orderAction";
import orderApi from "../../api/orderApi";
import userApi from "../../api/userApi";
import * as Icon from "react-bootstrap-icons";
import { Modal } from "react-bootstrap";
import { bindActionCreators } from "redux";
export interface IOrderDetailsProps {
  authAction: any;
  orderAction: any;
  userData: IUser;
  orderDetailsData: IOrderDetails;
  history: any;
  match: any;
}
export interface IOrderDetailsState {
  orderDetails: IOrderDetails;
  error: string;
  showModal: boolean;
  userBlocked: number;
  loading: boolean;
  loadingPost: boolean;
}

export class OrderDetails extends Component<
  IOrderDetailsProps,
  IOrderDetailsState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      orderDetails: {
        id: 0,
        datetime: "",
        totalsum: 0,
        restaurant_id: 0,
        restaurant_name: "",
        user_id: 0,
        user_username: "",
        user_fullname: "",
        status_name: "",
        status_title: "",
        status_last_element: -1,
        orderHistory: [],
        orderedMeals: [],
        userBlocked: 0,
      },
      error: "",
      showModal: false,
      userBlocked: 0,
      loading: false,
      loadingPost: false,
    };
    this.isLogged = this.isLogged.bind(this);
    this.get = this.get.bind(this);
    this.blockUser = this.blockUser.bind(this);
    this.unblockUser = this.unblockUser.bind(this);
  }
  componentDidMount() {
    this.isLogged();
    const id = this.props.match.params.id;
    this.get(id);
  }
  redirect = (path: string) => {
    this.props.history.push(path);
  };
  handleModalShow = () => {
    this.setState({
      showModal: true,
    });
  };
  handleModalClose = () => {
    this.setState({
      showModal: false,
    });
  };
  async isLogged() {
    if (!this.props.userData.username || this.props.userData.username === "") {
      try {
        this.setState({
          loading: true,
        });
        const user = await authApi.getUserInfo();
        await this.props.authAction.login(user);
        this.setState({
          error: "",
          loading: false,
        });
      } catch (err) {
        this.redirect("/login");
      }
    }
  }
  async get(id: number) {
    try {
      this.setState({
        loading: true,
      });
      const orderDetails: IOrderDetails = await orderApi.get(id);
      await this.props.orderAction.get(orderDetails);
      this.setState({
        userBlocked: orderDetails.userBlocked,
        error: "",
        loading: false,
      });
    } catch (err) {
      this.setState({
        error: err,
        loading: false,
      });
    }
  }
  async blockUser() {
    try {
      this.setState({
        loadingPost: true,
      });
      await userApi.blocked(
        this.props.orderDetailsData.user_id,
        this.props.orderDetailsData.restaurant_id,
        "block"
      );
      this.setState({
        userBlocked: 1,
        error: "",
        loadingPost: false,
      });
    } catch (err) {
      this.setState({
        error: err,
        loadingPost: false,
      });
    }
    this.handleModalClose();
  }
  async unblockUser() {
    try {
      this.setState({
        loadingPost: true,
      });
      await userApi.blocked(
        this.props.orderDetailsData.user_id,
        this.props.orderDetailsData.restaurant_id,
        "unblock"
      );
      this.setState({
        userBlocked: 0,
        error: "",
        loadingPost: false,
      });
    } catch (err) {
      this.setState({
        error: err,
        loadingPost: false,
      });
    }
    this.handleModalClose();
  }
  render() {
    if (this.state.loading) {
      return (
        <p className="text-center">
          <span className="spinner-border text-secondary" role="status">
            <span className="sr-only">Loading...</span>
          </span>
        </p>
      );
    }
    let userElement = <Fragment></Fragment>;
    if (this.props.userData.role == "owner") {
      if (this.state.userBlocked == 1) {
        userElement = (
          <Fragment>
            <button
              className="btn btn-info oi-button"
              onClick={this.handleModalShow}
              disabled={this.state.loadingPost}
            >
              <Icon.CheckCircle />{" "}
              {this.state.loadingPost ? "Unblock User" : "Unblock User"}
            </button>
          </Fragment>
        );
      } else {
        userElement = (
          <Fragment>
            <button
              className="btn btn-danger oi-button"
              onClick={this.handleModalShow}
              disabled={this.state.loadingPost}
            >
              <Icon.Trash />
              {this.state.loadingPost ? "Block User" : "Block User"}
            </button>
          </Fragment>
        );
      }
    }
    var date = new Date(
      this.props.orderDetailsData.datetime.replace(/-/g, "/")
    ).toLocaleDateString("EN-US");
    return (
      <Fragment>
        <div className="oi-container">
          <div className="oi-details">
            {this.state.error && (
              <div className="alert alert-danger">{this.state.error}</div>
            )}
            <div className="oi-title-section">Order Details</div>
            <div className="row">
              <div className="col-sm-1">
                <Icon.BagCheck className="oi-shop-icon" />
              </div>
              <div className="col-sm-2 oi-field">
                <div className="oi-field-title">Restaurant</div>
                <div className="oi-field-value" id="oi-restaurant-name">
                  {this.props.orderDetailsData.restaurant_name}
                </div>
              </div>
              <div className="col-sm-2 oi-field">
                <div className="oi-field-title">Status</div>
                <div className="oi-field-value" id="oi-restaurant-status">
                  {this.props.orderDetailsData.status_title}
                </div>
              </div>
              <div className="col-sm-2 oi-field">
                <div className="oi-field-title">Date</div>
                <div className="oi-field-value" id="oi-restaurant-date">
                  {date}
                </div>
              </div>
              <div className="col-sm-2 oi-field">
                <div className="oi-field-title">Total sum</div>
                <div className="oi-field-value" id="oi-restaurant-totalsum">
                  $
                  {parseFloat(
                    this.props.orderDetailsData.totalsum.toString()
                  ).toFixed(2)}
                </div>
              </div>
              <div className="col-sm-3 oi-field">
                <div className="oi-field-value">
                  <span className="oi-field-title">User: </span>
                  {this.props.orderDetailsData.user_fullname} {userElement}
                </div>
              </div>
            </div>
            <hr />
            <div>
              <div className="row">
                <div className="col-sm-6 oi-ordered-meals-section oi-left-section">
                  <div className="oi-title-section">Ordered Meals</div>
                  {this.props.orderDetailsData.orderedMeals.map((om, index) => (
                    <div className="row" key={index}>
                      <div className="col-sm-1">
                        <Icon.UiChecks className="oi-shop-icon-small" />
                      </div>
                      <div className="col-sm-4 oi-field-small">
                        <div className="oi-field-title-small">Meal</div>
                        <div
                          className="oi-field-value-small"
                          id="oi-meals-meal-name"
                        >
                          {om.meal_name}
                        </div>
                      </div>
                      <div className="col-sm-2 oi-field-small">
                        <div className="oi-field-title-small">Price</div>
                        <div
                          className="oi-field-value-small"
                          id="oi-meals-meal-price"
                        >
                          ${parseFloat(om.meal_price.toString()).toFixed(2)}
                        </div>
                      </div>
                      <div className="col-sm-2 oi-field-small">
                        <div className="oi-field-title-small">Quantity</div>
                        <div
                          className="oi-field-value-small"
                          id="oi-meals-meal-quantity"
                        >
                          {om.quantity}
                        </div>
                      </div>
                      <div className="col-sm-2 oi-field-small">
                        <div className="oi-field-title-small">Total sum</div>
                        <div
                          className="oi-field-value-small"
                          id="oi-meals-meal-totalsum"
                        >
                          $
                          {parseFloat(
                            (om.quantity * om.meal_price).toString()
                          ).toFixed(2)}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className="col-sm-6 oi-ordered-meals-section">
                  <div className="oi-title-section">Order History</div>
                  {this.props.orderDetailsData.orderHistory.map((oh, index) => (
                    <div className="row" key={index}>
                      <div className="col-sm-1">
                        <Icon.HourglassSplit className="oi-shop-icon-small" />
                      </div>
                      <div className="col-sm-4 oi-field-small">
                        <div className="oi-field-title-small">Datetime</div>
                        <div
                          className="oi-field-value-small"
                          id="oi-history-datetime"
                        >
                          {new Date(
                            oh.datetime.replace(/-/g, "/")
                          ).toLocaleDateString("EN-US")}{" "}
                          {new Date(
                            oh.datetime.replace(/-/g, "/")
                          ).toLocaleTimeString("EN-US")}
                        </div>
                      </div>
                      <div className="col-sm-4 oi-field-small">
                        <div className="oi-field-title-small">Status</div>
                        <div
                          className="oi-field-value-small"
                          id="oi-history-status"
                        >
                          {oh.status}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal show={this.state.showModal} onHide={this.handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Block/Unblock User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to{" "}
            {this.state.userBlocked == 1 ? "unblock" : "block"} user:{" "}
            {this.props.orderDetailsData.user_fullname} (username:{" "}
            {this.props.orderDetailsData.user_username}).
          </Modal.Body>
          <Modal.Footer>
            {this.state.userBlocked == 1 ? (
              <button
                className="btn btn-success"
                onClick={this.unblockUser}
                disabled={this.state.loadingPost}
              >
                <Icon.CheckCircle />
                {this.state.loadingPost ? "Unblock..." : "Unblock"}
              </button>
            ) : (
              <button
                className="btn btn-danger"
                onClick={this.blockUser}
                disabled={this.state.loadingPost}
              >
                <Icon.Trash />
                {this.state.loadingPost ? "Block..." : "Block"}
              </button>
            )}
            <button
              className="btn btn-secondary"
              onClick={this.handleModalClose}
              disabled={this.state.loadingPost}
            >
              Cancel
            </button>
          </Modal.Footer>
        </Modal>
      </Fragment>
    );
  }
}

function mapStateToProps(state: any, ownProps: any) {
  return {
    userData: state.user,
    orderDetailsData: state.orderDetails,
  };
}
function mapDispatchToProps(dispatch: any) {
  return {
    authAction: bindActionCreators(authAction, dispatch),
    orderAction: bindActionCreators(orderAction, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(OrderDetails);
