import React, { Component, Fragment } from "react";
import * as Icon from "react-bootstrap-icons";
import "../../App.css";
import { IUser } from "../../interfaces/IUser";
import { Modal } from "react-bootstrap";
import { IMeal } from "../../interfaces/IMeal";
export interface IMealDetailsProps {
  history: any;
  user: IUser;
  meal: IMeal;
  onMealSave: any;
  onMealDelete: any;
  addToCart: any;
}
export interface IMealDetailsState {
  edit: boolean;
  editId: number;
  editName: string;
  editDesc: string;
  editPrice: number;
  showDeletionModal: boolean;
  errorEdit: string;
  quantity: number;
  loading: boolean;
}

export class MealDetails extends Component<
  IMealDetailsProps,
  IMealDetailsState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      edit: false,
      editId: -1,
      editName: "",
      editDesc: "",
      editPrice: 0,
      showDeletionModal: false,
      errorEdit: "",
      quantity: 0,
      loading: false,
    };
    this.onSave = this.onSave.bind(this);
    this.onDelete = this.onDelete.bind(this);
  }
  componentDidMount() {}
  redirect = (path: string) => {
    this.props.history.push(path);
  };

  onChangeHandler = (event: any) => {
    this.setState({
      ...this.state,
      [event.target.name]: event.target.value,
    });
  };

  addToCart = () => {
    this.props.addToCart({
      meal_id: this.props.meal.id,
      meal_name: this.props.meal.name,
      meal_price: parseFloat(this.props.meal.price.toString()).toFixed(2),
      quantity: parseInt(this.state.quantity.toString()),
    });
    this.setState({
      quantity: 0,
    });
  };

  openEdit = () => {
    this.setState({
      edit: true,
      editId: this.props.meal.id,
      editName: this.props.meal.name,
      editDesc: this.props.meal.description,
      editPrice: this.props.meal.price,
    });
  };

  closeEdit = () => {
    this.setState({
      edit: false,
      errorEdit: "",
    });
  };

  handleModalShow = () => {
    this.setState({
      showDeletionModal: true,
    });
  };
  handleModalClose = () => {
    this.setState({
      showDeletionModal: false,
    });
  };

  async onSave() {
    if (this.state.editName.length < 3 || this.state.editDesc.length < 3) {
      this.setState({
        errorEdit:
          "Name and Description should be longer than 3 characters! Price is required!",
      });
      return;
    } else if (
      !this.state.editPrice ||
      this.state.editPrice == 0 ||
      isNaN(this.state.editPrice)
    ) {
      this.setState({
        errorEdit: "Please provide valid price!",
      });
      return;
    }
    this.setState({
      loading: true,
    });
    await this.props.onMealSave(
      this.state.editId,
      this.state.editName,
      this.state.editDesc,
      parseFloat(this.state.editPrice.toString()).toFixed(2)
    );
    this.setState({
      edit: false,
      errorEdit: "",
      loading: false,
    });
  }

  async onDelete() {
    this.setState({
      loading: true,
    });
    await this.props.onMealDelete(this.props.meal.id);
    this.setState({
      loading: false,
    });
    this.handleModalClose();
  }
  render() {
    return (
      <div className="meal-detail row">
        <div className="col-sm-1">
          <Icon.UiChecks className="md-shop-icon" />
        </div>
        <div className="col-sm-7">
          {this.state.errorEdit && (
            <div className="alert alert-danger">{this.state.errorEdit}</div>
          )}
          <div className="md-name">
            {this.state.edit ? (
              <div className="">
                <input
                  type="text"
                  className="form-control md-edit-name"
                  value={this.state.editName}
                  name="editName"
                  onChange={this.onChangeHandler}
                  placeholder="Name..."
                />
              </div>
            ) : (
              this.props.meal.name
            )}
          </div>
          <div className="md-desc">
            <span className="md-desc-title">Description: </span>
            {this.state.edit ? (
              <div className="">
                <textarea
                  className="form-control md-edit-desc"
                  value={this.state.editDesc}
                  name="editDesc"
                  onChange={this.onChangeHandler}
                  placeholder="Description..."
                  rows={2}
                ></textarea>
              </div>
            ) : (
              this.props.meal.description
            )}
          </div>
        </div>
        <div className="col-sm-2">
          <div className="md-price">
            {this.state.edit ? (
              <div className="">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">$</span>
                  </div>
                  <input
                    type="text"
                    className="form-control md-edit-price"
                    value={this.state.editPrice}
                    name="editPrice"
                    onChange={this.onChangeHandler}
                    placeholder="Price..."
                  />
                </div>
              </div>
            ) : (
              <span className="md-price-title">
                ${parseFloat(this.props.meal.price.toString()).toFixed(2)}
              </span>
            )}
          </div>
        </div>
        <div className="col-sm-2 md-button-container">
          {this.props.user.role === "owner" ? (
            <Fragment>
              {this.state.edit ? (
                <Fragment>
                  <button
                    type="button"
                    className="md-button btn btn-success"
                    onClick={this.onSave}
                    disabled={this.state.loading}
                  >
                    <Icon.Save /> {this.state.loading ? "Save..." : "Save"}
                  </button>
                  <button
                    type="button"
                    className="md-button btn btn-danger"
                    onClick={this.closeEdit}
                    disabled={this.state.loading}
                  >
                    <Icon.XOctagon /> Close
                  </button>
                </Fragment>
              ) : (
                <Fragment>
                  <Icon.PencilSquare
                    className="md-button-icon-edit"
                    onClick={this.openEdit}
                  />
                  <Icon.Trash
                    className="md-button-icon-delete"
                    onClick={this.handleModalShow}
                  />
                </Fragment>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <div>
                <input
                  type="number"
                  min="0"
                  step="1"
                  value={this.state.quantity}
                  name="quantity"
                  onChange={this.onChangeHandler}
                  className="md-quantity"
                ></input>
              </div>
              <div>
                <button
                  className="btn btn-primary md-add-to-cart-btn"
                  onClick={this.addToCart}
                  disabled={!this.state.quantity || this.state.quantity < 1}
                >
                  <Icon.Bag /> Add to cart
                </button>
              </div>
            </Fragment>
          )}
        </div>
        <Modal
          show={this.state.showDeletionModal}
          onHide={this.handleModalClose}
        >
          <Modal.Header closeButton>
            <Modal.Title>Meal deletion!</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Are you sure you want to delete meal: {this.props.meal.name}
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={this.handleModalClose}
              disabled={this.state.loading}
            >
              Cancel
            </button>
            <button
              className="btn btn-danger"
              onClick={this.onDelete}
              disabled={this.state.loading}
            >
              Delete
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default MealDetails;
